/* reset */

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button,
section,
article,
header,
main,
footer,
nav,
aside,
details {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

fieldset,
img {
  border: 0 none;
}

dl,
ul,
ol,
menu,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

input,
select,
textarea {
  font-size: 100%;
  vertical-align: middle;
}

button {
  font-size: 100%;
  vertical-align: middle;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* 뷰표트 변환시 폰트크기 자동확대 방지 */

input {
  &[type="text"],
  &[type="password"],
  &[type="submit"],
  &[type="search"] {
    -webkit-appearance: none;
    border-radius: 0;
  }

  &:checked[type="checkbox"] {
    background-color: #666;
    -webkit-appearance: checkbox;
  }
}

button {
  -webkit-appearance: button;
  border-radius: 0;
}

input {
  &[type="button"],
  &[type="submit"],
  &[type="reset"],
  &[type="file"] {
    -webkit-appearance: button;
    border-radius: 0;
  }

  &[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

/* 기본 폰트 설정 */
body,
th,
td,
input,
select,
textarea,
button {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-family: "Noto Sans", "Malgun Gothic", "맑은 고딕", sans-serif;
  color: #171429;
}
@media screen and (max-width: $xl) {
  body,
  th,
  td,
  input,
  select,
  textarea,
  button {
    font-size: 14px;
  }
}
@media screen and (max-width: $lg) {
  body,
  th,
  td,
  input,
  select,
  textarea,
  button {
    font-size: 12px;
  }
}
@media screen and (max-width: $md) {
  body,
  th,
  td,
  input,
  select,
  textarea,
  button {
    font-size: 14px;
  }
}

/* color값은 디자인가이드에 맞게사용 */
a {
  color: #333;
  text-decoration: none;

  &:active,
  &:hover {
    text-decoration: none;
  }
}

address,
caption,
cite,
code,
dfn,
em,
var,
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}

hr {
  display: none;
}

input[type="password"] {
  font-family: "Malgun Gothic";
}

/* skipnavigation */
#u-skip a {
  position: absolute;
  left: -3000%;

  &:focus {
    display: block;
    left: 0;
    top: 0;
    z-index: 10000000000;
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #0072bc;
    color: #fff;
    text-align: center;
  }
}

/* 이미지 스프라이트 */
.img-g {
  display: inline-block;
  overflow: hidden;
  // background: url(../../images/img-comm.svg) no-repeat;
  background: url(../assets/images/img-comm.svg) no-repeat;
  background-size: 400px 840px;
  vertical-align: top;
}

/* 이미지 */

/* global */

.ir-pm {
  display: block;
  overflow: hidden;
  font-size: 0px;
  line-height: 0;
  text-indent: -9999px;
}

/* 사용된 이미지내 의미있는 텍스트의 대체텍스트를 제공할때 */

.ir-wa {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: -10;
  width: 100%;
  height: 100%;
}

/* 중요한 이미지 대체텍스트로 이미지off시에도 대체 텍스트를 보여주고자 할때 */

.blind,
caption {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}

/* 대체텍스트가 아닌 접근성을 위한 숨김텍스트를 제공할때 */

.f_l {
  float: left;
}

.f_r {
  float: right;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.tbl {
  border-collapse: collapse;
  border-spacing: 0;
}

picture {
  img {
    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* 테이블 초기화 */

.clear-g {
  display: block;
  overflow: visible;
  width: auto;
  clear: both;
  *zoom: 1;

  &:after {
    display: block;
    visibility: hidden;
    height: 0;
    font-size: 0;
    clear: both;
    content: "";
  }
}

.pc {
  display: block !important;
}
.t {
  display: none !important;
}
.md {
  display: none !important;
}
.m {
  display: none !important;
}

@media screen and (max-width: $xl) {
  .p100 {
    padding: 100px 0 !important;
  }
  .pc {
    display: none !important;
  }
  .t {
    display: block !important;
  }
  .m {
    display: none !important;
  }
  picture {
    display: flex;
    justify-content: center;
    // width: 100%;
    margin: auto auto;
    .pc {
      display: block !important;
    }
    .m {
      display: none !important;
    }
  }
}
@media screen and (max-width: $lg) {
  // [class*="sec-"] {
  //   padding: 100px 0 !important;
  // }
  .pc {
    display: none !important;
  }
  .t {
    display: none !important;
  }
  .m {
    display: none !important;
  }
}
@media screen and (max-width: $md) {
  .pc {
    display: none !important;
  }
  .t {
    display: none !important;
  }
  .m {
    display: block !important;
  }
  picture {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    .pc {
      display: none !important;
    }
    .m {
      display: block !important;
    }
  }
}
@media screen and (max-width: $sm) {
  .p100 {
    padding: 50px 0 !important;
  }
}

// body {
//   // background: url(../../pc.png) 50% -52px no-repeat !important;
//   background: url(../../m.png) 0 1px / 100% auto no-repeat !important;
// }
// #root,
// .modal-field {
//   opacity: 0.7;
// }
