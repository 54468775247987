@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin objectfit {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50%;
}

@mixin thumbLine {
  display: block;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.06;
    filter: alpha(opacity=6);
    border: 1px solid #000;
  }
}

@mixin posX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin posY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin posXY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ani-s {
  transform: translateY(60px);
  opacity: 0;
  transition: all 0.5s;
}

@mixin ani-e {
  transform: translateY(0);
  opacity: 1;
}

// lineawesome
@mixin lineaw {
  font-family: "Line Awesome Free";
  font-weight: 900;
}
@mixin arrow-next {
  content: "\f105";
  font-family: "Line Awesome Free";
  font-weight: 900;
}
@mixin arrow-prev {
  content: "\f104";
  font-family: "Line Awesome Free";
  font-weight: 900;
}
@mixin arrow-top {
  content: "\f106";
  font-family: "Line Awesome Free";
  font-weight: 900;
}
@mixin arrow-bot {
  content: "\f107";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

// 말줄임
@mixin ell {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
@mixin ell2 {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  height: 2.6em;
}
@mixin ell3 {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5em;
  height: 4.5em;
}
@mixin ell4 {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.5em;
  height: 6em;
}

@mixin img-g {
  display: inline-block;
  overflow: hidden;
  background: url(../assets/images/img-comm.svg) no-repeat;
  background-size: 400px 840px;
  vertical-align: middle;
  text-indent: -9999px;
  font-size: 0;
  line-height: 0;
}

@mixin box-line {
  border: 1px solid $border-color;
  border-top: 1px solid #19a0cf;
}

@mixin ico_square {
  position: relative;
  padding-left: 12px;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    width: 3px;
    height: 3px;
    background: #555;
  }
}

@mixin position($p: absolute, $t: null, $b: null, $l: null, $r: null) {
  position: $p;
  top: $t;
  bottom: $b;
  left: $l;
  right: $r;
}

// fonts
@mixin f-popp {
  font-family: "Poppins", sans-serif;
}
@mixin f-spoqa {
  font-family: "Spoqa Han Sans Neo", sans-serif;
}
@mixin f-noto {
  font-family: "Noto Sans", sans-serif;
}
@mixin mso {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
